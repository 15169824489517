import { ChangeDetectionStrategy, Component, OnInit } from "@angular/core";
import { AnimationLoader, AnimationOptions, LottieComponent, provideLottieOptions } from "ngx-lottie";
import { LoggerService } from "@services/logger-service.service";
import { Title } from "@angular/platform-browser";
import { environment } from "@env/environment";
import { RouterLink } from "@angular/router";

@Component({
  selector: "app-not-found",
  standalone: true,
  template: `
    <div class="not-found">
      <div class="not-found__hero">
        <ng-lottie [options]="options"></ng-lottie>
      </div>
      <div class="not-found__title">
        <h1>Страница не найдена</h1>
      </div>
      <div class="not-found__description">
        Она была удалена, либо вовсе не существовала на сайте
      </div>
      <div class="not-found__buttons">
        <a [routerLink]="'/'" class="button-link">Перейти на главную</a>
      </div>
    </div>
  `,
  styleUrls: ["./not-found.component.scss"],
  imports: [
    LottieComponent,
    RouterLink
  ],
  providers: [
    provideLottieOptions({
      player: () => import("lottie-web"),
    }),
    AnimationLoader
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})

export class NotFoundComponent implements OnInit {

  options: AnimationOptions = {
    path: "/assets/animation/404.json",
  };

  constructor(private logger: LoggerService,
              private titleService: Title) {
    this.logger.debug(this.constructor.name + " is created.");
  }

  public ngOnInit() {
    this.titleService.setTitle(environment.title + " - " + "Ошибка 404");
  }
}
